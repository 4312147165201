.footer {
    background-color: #333;
    color: #fff;
    padding: 20px;
    text-align: center;
    position: relative;
    width: 100%;
    bottom: 0;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 10px;
  }
  
  .footer ul li {
    display: inline;
  }
  
  .footer ul li a {
    color: #fff;
    text-decoration: none;
  }
  
  .footer ul li a:hover {
    text-decoration: underline;
  }
  