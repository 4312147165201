.privacy-policy-page {
    padding: 20px;
  }
  
  .privacy-policy-page h1, .privacy-policy-page h2 {
    margin-bottom: 20px;
  }
  
  .privacy-policy-page ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .privacy-policy-page p {
    margin-bottom: 20px;
  }
  