.detail-commande-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  background-color: #a71717;
  color: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  z-index: 1000;
  padding: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 720px; /* Limite la hauteur du popup */
  overflow: hidden; /* Cache le débordement */
}

.popup-header {
  display: ruby;
  justify-content: space-between;
  text-align: center;
  width: 100%;
}

.popup-footer {
  display: ruby;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.popup-header h2 {
  font-size: 18px;
}

.close-button {
  background: black;
  border: none;
  font-size: 16px;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  margin-top: 7px;
  right: 7px;
}

.detail-commande-popup p {
  text-align: center;
  margin: 2px 0;
}

.detail-commande-table-wrapper {
  flex: 1;
  width: 100%;
  overflow-y: auto; /* Active le défilement vertical */
}

.detail-commande-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.detail-commande-table th, .detail-commande-table td {
  padding: 2px;
  text-align: center;
}

.separator {
  width: 100%;
  border: 1px solid white;
  margin: 10px 0;
}

.total-commande {
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.tel-paie {
  font-weight: bold;
  text-align: center;
  color: black;
  border-radius: 35px;
  font-size: 19px;
}

.payment-options {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
}

.payment-btn {
  border-color: #a71717;
  border-radius: 51px;
}

.delete-ligne {
  border-color: #f7f304;
  background-color: #141814;
  border-radius: 51px;
}

.payment-btn img {
  width: 40px;
  height: 40px;
}

.qrcode-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed yellow;
  border-radius: 15px;
  padding: 5px;
  margin: 10px 0;
}

.total-commande {
  font-size: larger;
  font-weight: bold;
  
 
}

.rechercher-button {
  padding: 10px 20px;
  text-align: center;
  font-size: 16px;
  background-color: #ffc107;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  color: #141814;
  cursor: pointer;
  height: fit-content;
  transition: background-color 0.3s ease;
}
