

.category-grid {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding: 10px 0;
    gap: 20px;
  }
  
  .category-card {
    display: inline-block;
    border: 1px solid #7d1919;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
    flex: 0 0 auto;
  }
  
  .category-card:hover {
    transform: scale(1.05);
  }
  
  .category-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  
  .category-name {
    padding: 10px;
    background-color: #7d1919;
    color: #ffffff;
  }
  
  .select-list {
    padding: 20px;
    text-align: center;
    background: black;
}
  
  .checkboxline {
    margin: 20px 0;
  }
  
  #trackOrderBtn {
    margin-top: -22px;
  }
  
.select-list label {
    font-size: 12px;
    margin-right: 10px;
    color: #94d21e;
}
.labelnav {
    font-size: 15px;
    margin-right: 0px;
    color: #f4f4f5;
    left: 15px;
    text-align: center;
    margin-left: 0px;
}

.select-list select {
    padding: 10px;
    font-size: 16px;
    margin-right: 20px;
    color: #aeb128;
    background: #0c0505;
}

.select-list button {
    padding: 10px 20px;
    font-size: 16px;
    top: 5px;
    background-color: #ffc107;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    color: #141814;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.select-list button:hover {
    background-color: #e0a800;
}
