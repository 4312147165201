.facture-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.facture-content {  
  background-color: #fff;
  border-radius: 10px;
  max-width: 80%;
  padding: 20px;
  font-size: xx-small;
  position: relative;
  width: 400px;
  color: black;
}

.facture-header {
  display: ruby;
  justify-content: space-between;
  align-items: center;
}

.facture-logo {
  width: 75px;
  height: 75px;
}

.facture-cachet {
  width: 75px;
  margin-right: -279px;
  margin-top: -46px;
  /* margin-left: -263px; */
}
.facture-h3{
  text-align: left;
  width: 100%;
  margin: 10px 0;
  color: #000;
}
.facture-h2, .facture-numero, .facture-details h3,h3{
  text-align: center;
  width: 100%;
  margin: 10px 0;
  color: #000;
}



.close-popup {
  background: black;
  border: none;
  font-size: 16px;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  position: absolute;
  margin-top: 7px;
  right: 7px;
}

.facture-client-info  {
  padding: 10px;
  background-color: bisque;
  border-radius: 35px;
}

.facture-client-info,
.facture-details,
.facture-footer {
  margin-top: 20px;
 
}

.facture-table-wrapper {
  max-height: 300px; /* ajustez cette valeur selon vos besoins */
  overflow-y: auto;
  margin-bottom: 20px; /* espace en bas de la table pour le style */
}

.facture-table {
  width: 100%;
  border-collapse: collapse;
}

.facture-table th,
.facture-table td {
  border: 1px solid #ddd;
  padding: 8px;
}
.facture-footer {
  text-align: center;
  margin-top: 20px;
  position: relative;
}

.print-icon {
  width: 30px;
  cursor: pointer;
  position: fixed;
  margin: -16px;
}