.product-list {
    padding: 20px;
    text-align: center;
    background: black;
}

.product-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.product-card {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    background: green;
}

.product-card:hover {
    transform: translateY(-10px);
}

.product-image-container {
    width: 123px;
    height: 122px;
    margin: 0 auto;
    border: 0px solid white;
    border-radius: 50%;
    overflow: hidden;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.product-card h2 {
    font-size: 18px;
    margin: 10px 0;
}

.product-card p {
    font-size: 14px;
    margin: 5px 0;
}

.product-card button {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: -webkit-fill-available;
    background-color: #7da52a;
    border: 1px solid #8ab794;
    padding: 0.375rem 0.75rem;
    font-size: 1.1rem;
    line-height: 1.5;
    border-radius: 8.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.product-buttonAdd {
    display: inline-block;
    /* font-weight: 400; */
    color: #008000;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    font-weight: bold;
    /* text-size-adjust: auto; */
    /* font-size: 18px; */
    width: -webkit-fill-available;
    background-color: #69f35e;
    border: 1px solid #8ab794;
    padding: 0.375rem 0.75rem;
 
    line-height: 2.5;
    border-radius: 8.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.product-card button:hover {
    background-color: #218838;
}


.categorySelect-list {
    padding: 20px;
    display: flex;
    text-align: center;
    border: #9fff00;
    border-style: dotted;
    border-radius: 25px;
}
.search-bar-label {
    font-size: 18px;
    margin-right: 10px;
    color: #94d21e;
}
.categorySelect-label {
    font-size: 18px;
    margin-right: 10px;
    color: #94d21e;
}
.search-bar{
    padding: 10px;
    font-size: 16px;
    margin-right: 20px;
    color: #097a1c;
    background: #0c0505;
    width: -webkit-fill-available;
    text-align: center;

}
.search-bar input {
    align-items: center;
    font-size: 22px;
    margin-right: 10px;
    text-align: center;
    border-radius: 35px;
    /* flex: 1 1; */
}
  
  .search-bar button {
    padding: 10px 20px;
    font-size: 16px;
  }
.categorySelect-select  {
    padding: 10px;
    font-size: 16px;
    margin-right: 20px;
    color: #aeb128;
    background: #0c0505;
}
