.header {
  background-color: #08720b;
  padding: 10px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  flex-wrap: wrap;
}

.left, .center, .right {
  flex: 1;
}

.center {
  text-align: center;
}

.manavbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  background: #cb0606;
}

header .left img {
  height: 50px;
}

header .center h1 {
  margin: 0;
  font-size: 24px;
  text-align: center;
}

header .right p {
  margin: 0;
  font-size: 14px;
}

.navbar {
  position: relative;
  z-index: 10; /* S'assurer que la barre de navigation a un z-index supérieur */
}

.hamburger {
  background-color: #e92222;
  border: 2px solid #4CAF50;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  position: fixed ;
  top: 10px;
  right: 20px;
}

.hamburger:hover {
  background-color: #c51313;
}

.labelnav {
  background-color: #f44336;
  color: white;
  padding: 10px;
  width: 100%; /* Largeur de 100% */
  text-align: center;
  margin: 10px 0; /* Ajout d'un margin pour espacer les éléments */
}

.nav-links {
  display: none;
  flex-direction: column;
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  background-color: #333;
  padding: 10px;
  border-radius: 10px;
  z-index: 15; /* S'assurer que les liens de navigation sont au-dessus */
}

.nav-links.open {
  display: flex;
}

.nav-links button {
  background-color: #444;
  border: none;
  color: white;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  border-radius: 10px;
}

.nav-links button:hover {
  background-color: #555;
}

.GoToAncre {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 9px 56px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 28px;
  margin-left: 10px;
}

.GoToAncre:hover {
  background-color: #45a049;
}

/* Media queries pour ajuster le style en fonction de la taille de l'écran */
@media (min-width: 768px) {
  .container {
    flex-wrap: nowrap;
  }

  .labelnav {
    width: 100%; /* Largeur de 100% */
    text-align: center; /* Centré horizontalement */
    margin: 10px 0;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .GoToAncre {
    position: relative;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .GoToAncre {
    width: 100%;
    margin: 10px 0; /* Espacement au-dessus et en dessous */
  }

  .right {
    justify-content: center;
  }
}
