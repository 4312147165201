.cart-popup, .detail-commande-popup {
  position: fixed;
  top: 20px; /* Ajustez cette valeur pour l'aligner avec la bannière */
  right:  0;
  width: 245px;
  background-color: #a71717; /* Utilisation d'une couleur de fond foncée pour une meilleure visibilité */
  color: white;
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(54, 53, 53, 0.1);
  border-radius: 30px;
  z-index: 1000;
  padding: 3px;
}

.command_sms {
  color: rgb(255, 255, 255);
  font-size: 14px;
  text-decoration: dotted;
  font-weight: bold;
  hyphenate-character: no;
}

.cart-header-th {
  box-sizing: border-box;
  color: #f3ecec;
}

.cart-span-total {
  color: chartreuse;
  font-size: 21px;
  text-decoration: dotted;
  font-weight: bold;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-btn-action {
  width: 5px;
  height: 5px;
}

.cart-header h2 {
	flex: 1 1;
	font-size: 10px;
	margin: -13px;
	text-align: center;
}

.cart-td, .detail-commande-td {
  color: black;
  font-size: 79%;
  background: #e8ffd4;
}

.cart-total {
  color: black;
  font-size: 100%;
  text-decoration: dotted;
  background: #e8ffd4;
}
.close-btn {
	background: none;
	/* border: 1px; */
	color: #fff;
	cursor: pointer;
	font-size: 16px;
	border-radius: 50px;
	border-color: white;
}

.radio-option {
  margin-right: 5px;
}

.btn-actions {
  width: 20px;
  height: 20px;
}

.cart-content {
  max-height: fit-content;
    max-height: 520px; /* ajustez cette valeur selon vos besoins */
 
  color: rgb(15, 4, 4);
  width: 237px;
}

.cart-content p {
  /* max-height: 461px; */
  overflow-y: auto;
  color: rgb(255, 255, 255);
  text-align: center;
  /* margin: 0px; */
  margin-top: -15px;
}
.cart-items {
  flex: 1;
  max-height: 200px; /* ajustez cette valeur selon vos besoins */
  overflow-y: auto;
  margin-bottom: 10px;
}

.cart-footer {
  display: inherit;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  color: aliceblue;
}

.cart-footer p {
  margin: 0;
  font-size: 18px;
}

.cart-popup.rounded, .detail-commande-popup.rounded {
  border-radius: 10px;
}

.btn img {
  width: 20px;
  height: 20px;
}

/* Style pour le popup sur les écrans mobiles */
@media (max-width: 600px) {
  .cart-popup, .detail-commande-popup {
      width: 281px;
  }
}

.command_side {
  margin-top: 20px;
  text-align: center;
  background: darkgoldenrod;
  border-radius: 18px;
}

.command_button {
  margin-top: 5px;
  text-align: center;
  border-radius: 18px;
}

.command_side h3 {
  margin-bottom: 4px;
}

.rounded-input {
  width: calc(100% - 0px);
  text-align: center;
  color: black;
  padding: 5px;
  margin: 5px 0;
  border: none;
  border-radius: 30px;
  outline: none;
  box-sizing: border-box;
}
.valid-panier { 
  margin-top: 9px;
  text-align: center;
}