.product-popup {
  background-color: #00000080;
  color: #000;
  display: ruby;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.product-popup-content   {
  background-color: #fff;
  border-radius: 10px;
  max-width: 80%;
  padding: 20px;
  font-size: xx-small;
  position: relative;
  width: 400px;
}
.product-popup-image {
  width: 150px; /* Assurez-vous que la largeur et la hauteur sont égales pour un cercle parfait */
  height: 150px; /* Assurez-vous que la largeur et la hauteur sont égales pour un cercle parfait */
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 1px solid #004000;
  background-color: rgb(239, 252, 129);
  margin-bottom: 20px; /* Ajouter un espace entre l'image et le contenu */
}


.product-popup-content input,
.product-popup-content textarea,
.product-popup-content select  {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  color: black;
  border-radius: 25px; /* Bords arrondis */
  font-size: 10px;
}


.product-popup-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.product-popup-actions button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.product-popup-actions button:first-child {
  background-color: green;
  color: white;
  border: none;
  border-radius: 25px; /* Bords arrondis */
  cursor: pointer;
}

.product-popup-actions button:last-child {
  background-color: red;
  color: white;
  border: none;
  border-radius: 25px; /* Bords arrondis */
  cursor: pointer;
}
.image-upload-container {
  text-align: center;
  margin-top: 10px;
}
.image-upload-container input {
  text-align: center;
  margin-top: 10px;
}
.product-popup-content label {
  display: block;
  margin-top: 5px;
  color: black;
  text-align: left;
}
