/* Style de base pour tous les boutons */
.btn-panier {
    display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1.5rem;
    line-height: 1.5;
    border-radius: 5.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  /* Bouton Success */
  .btn-panier-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
  }
  
  .btn-panier-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
  }
  
  /* Bouton Info */
  .btn-panier-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
  }
  
  .btn-panier-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
  }
  
  /* Bouton Warning */
  .btn-panier-warning {
    color: #d2fc19;
    background-color: #ffc107;
    border-color: #ffc107;
  }
  
  .btn-panier-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
  }
  
  /* Bouton Danger */
  .btn-panier-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .btn-panier-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
  }
  
  /* Styles pour rendre les boutons arrondis */
  .btn-panier-rounded {
    border-radius: 30px;
  }
  