.rounded-input-search {
  width: calc(100% - 40px);
  text-align: center;
  color: black;
  padding: 7px;
  margin: 5px 0;
  border: none;
  border-radius: 30px;
  outline: none;
  box-sizing: border-box;
}

.command-header-th {
  box-sizing: border-box;
  color: #412c0d;
}

.command-client {
  color: black;
  font-size: 79%;
  background: #45880b;
  border-radius: 18px;
  width: 100%;
  height: 100%;
  padding: 18px;
  margin-top: 10px;
  text-align: center;
}

.command-client h1 {
  text-align: center;
  font-size: 23px;
}

.command-td {
  color: black;
  font-size: 81%; /* Taille par défaut pour les écrans mobiles */
  background: #e8ffd4;
}

@media (min-width: 768px) {
  .command-td {
      font-size: 150%; /* Taille pour les écrans de PC */
  }
}

.select-etat {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #493909;
  border: none;
  border-radius: 30px;
  font-weight: bold;
  color: #141814;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
