.command-manager {
  padding: 20px;
}

.filter-section {
  display: grid;
  gap: 10px;
  margin-bottom: 20px;
}
.command-manager-title {
  text-align: center;
}
.total-amount {
  text-align: center;
  margin-bottom: 20px;
}
.button-rounded {
  display: inline-block;
  color: #008000;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  font-weight: bold;
  font-size: large;
  width: -webkit-fill-available;
  background-color: #feff12;
  border: 1px solid #8ab794;
  padding: 0.375rem 0.75rem;
  line-height: 2.5;
  border-radius: 8.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.filter-section input,
.filter-section select{
  padding: 10px;
  color: darkgreen;
  font-size: 16px;
}

.orders-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.order-card {
  background-color: #08720b;
  color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 20px;
  width: 284px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.order-card img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.order-details {
  text-align: center;
  margin-top: 10px;
}

.order-details div {
  margin: 5px 0;
}

.btn {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: white;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-success {
  background-color: #28a745;
  color: white;
  border: none;
}

.btn-success:hover {
  background-color: #218838;
}

.btn-info {
  background-color: #17a2b8;
  color: white;
  border: none;
}

.btn-info:hover {
  background-color: #117a8b;
}

@media (max-width: 768px) {
  .orders-list {
    flex-direction: column;
    align-items: center;
  }
}
