
.cgv-container {
    max-width: 800px;
    display:flexbox;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.cgv-container h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #fffefe;
}

.cgv-container h2 {
    margin-top: 20px;
    font-size: 1.5em;
    color: #ffffff;
}

.cgv-container p {
    margin-bottom: 10px;
    text-align: justify;
    color: #ffffff;
}
