.card {
    display: flex;
    align-items: center;
    background-color: #09330a;
    border-radius: 10px;
    padding: 20px;
    margin: 10px 0;
    color: white;
  }
  
  .card-image {
    width: 100px;
    height: 100px;
    border-radius: 55%;
    margin-right: 20px;
    object-fit: cover;
  }
  
  .card-text {
    flex: 1;
  }
  