.login-admin-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    text-align: center;
    background-color: #108b0c; /* Couleur de fond verte */
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-admin-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffffff; /* Couleur du texte blanche */
  }
  
  .login-admin-container form {
    display: flex;
    flex-direction: column;
  }
  
  .login-admin-container input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 16px;
    color: red;
    text-align: center;
}
  
  .login-admin-container button {
    padding: 10px;
    font-size: 16px;
    color: #000000; /* Couleur du texte noire */
    background-color: #d4e157; /* Couleur de fond jaune */
    border: none;
    border-radius: 25px; /* Bords arrondis */
    cursor: pointer;
  }
  
  .login-admin-container button:hover {
    background-color: #c0ca33; /* Couleur de fond jaune plus foncé au survol */
  }
  