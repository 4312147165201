.about-page {
    padding: 20px;
    background: #3daa35;
  }
  
  .about-page h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .about-page section {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .about-page h2 {
    margin-bottom: 10px;
  }
  
  .about-page .card {
    margin-bottom: 20px;
  }
  .image-footer {
    /* background-color: #333; */
    color: #fff;
    padding: 60px;
    /* text-align: center; */
    position: fixed;
    width: 20%;
    bottom: -8px;
}
  
  @media (max-width: 767px) {
    .about-page img.desktop {
      display: none;
      text-align: center;
    }
  }
  
  @media (min-width: 768px) {
    .about-page img.mobile {
      display: none;
    }
  }
  