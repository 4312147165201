.banner-container {
    position: relative;
    width: 100%;
    height: 450px;
    overflow: hidden;
    background: black;
}

.banner-slide {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.banner-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-slide.active {
    opacity: 1;
}
